import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormModalService } from '@design/modals/form-modal/form-modal.service';

export abstract class AbstractReturnToReferrerComponent {
  returnToReferrerParamName = 'returnToReferrer';

  private activeRoute = inject(ActivatedRoute);
  private modalService = inject(FormModalService);

  private tryToReturnToReferer() {
    this.activeRoute.queryParams.subscribe((params) => {
      const documentReferer = document.referrer;
      if (params[this.returnToReferrerParamName] && documentReferer != '') {
        window.location.href = documentReferer;
      }
    });
  }

  close = () => {
    this.tryToReturnToReferer();
    this.modalService.closeModal();
  };

  setBackgroundStyle = () => {
    this.activeRoute.queryParams.subscribe((params) => {
      const documentReferer = document.referrer;
      if (params[this.returnToReferrerParamName] && documentReferer != '') {
        const backdrop = document.querySelector('.cdk-overlay-dark-backdrop');
        backdrop.classList.add('full-black-background');
      }
    });
  };
}
